import React from "react";
import "./Footer.css";
import twitterIcon from '../../Assets/images/twitter.png';
import instaIcon from '../../Assets/images/insta.svg';
import telegramIcon from '../../Assets/images/telegram.png';
import fbIcon from '../../Assets/images/facebook.png';
import mediumIcon from '../../Assets/images/medium.png';
const Footer = () => {
  return (
    <footer className="footer bg-[#00041a]  flex flex-wrap overflow-hidden">
            <div className="container  ">
                <div className="footer_inner_row">
                    <a href="/" aria-current="page" className="footer_logo_wrapper "></a>
                </div>

                <div className="footer_seperator flex flex-row items-center justify-center pb-0 pt-2 relative">
                    <div className="footer-seperator-line"></div>
                  
                </div>

                <div className="footer_copyright_wrapper flex flex-wrap justify-center gap-2 sm:justify-between items-center">
                    <div className="footer_copyright_text text-center sm:text-left">© 2024 Techmont. All rights reserved</div>
                    <div className="community_icon_wrapper pb-5 ml-9 sm:ml-0">
                        <div className="icon-holder">
                            <a href="https://x.com/Tech_Mont" target="_blank" className="icon_wrapper inline-block">
                                <img className="h-auto w-5 ftr-icon invert" src={twitterIcon} alt="Twitter" />
                            </a>
                        </div>
                            <div className="icon-holder">
                                <a href="https://medium.com/@Tech_Mont" target="_blank" className="icon_wrapper inline-block">
                                    <img className="h-auto w-6 ftr-icon invert" src={mediumIcon} alt="Medium" />
                                </a>
                            </div>
                            <div className="icon-holder">
                                <a href="https://www.facebook.com/TechMont" target="_blank" className="icon_wrapper inline-block">
                                    <img className="h-auto w-6 ftr-icon invert" src={fbIcon} alt="Facebook" />
                                </a>
                            </div>
                        <div className="icon-holder">
                            <a href=" https://www.instagram.com/techmont9" target="_blank" className="icon_wrapper inline-block">
                                <img className="h-auto w-6 ftr-icon" src={instaIcon} alt="Instagram" />
                            </a>
                        </div>

                        <div className="icon-holder">
                            <a href="https://t.me/TechMontGroup" target="_blank" className="icon_wrapper inline-block">
                                <img className="h-auto w-6 ftr-icon invert" src={telegramIcon} alt="Telegram" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
  );
};
export default Footer;