import React, { useState } from "react";
import faqImage from "../../Assets/images/king.webp";
import "../Faqs/Faqs.css";

const Faqs = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section id="faqsSrl" className="faqOuterSection  pt-5 md:pt-12 px-2 sm:px-4">
      <div className="container mx-auto rotateGradientAb">
        <div className="rm_main" data-aos="fade-up">
          <div className="h2div mx-auto w-full  max-w-20">
            <h3 className="text-center">Faq</h3>
          </div>
          <h2 className="adv-heading text-center text-white mt-3">
          Frequently  <span className="hero-communities">Asked</span>{" "}Questions   
          </h2>
        </div>
       
        <div className="flex flex-wrap mt-16">
  <div className="order-2 lg:order-1 w-full lg:w-1/3 md:w-1/2 px-0 md:px-4 mb-0 md:mb-6">
    <div className="rowtreebox">
      <div className="accordion">
        {faqData.slice(0, 4).map((item, index) => (
          <div key={index} className="accordion-item border-t ">
            <h2 className="accordion-header mb-0">
              <button
                className="accordion-button flex items-center w-full py-4 px-5 text-base text-white text-left transition focus:outline-none"
                onClick={() => handleToggle(index)}
              >
                {item.question}
                <svg
                  className={`ml-auto transform transition-transform ${
                    activeIndex === index ? "rotate-180" : ""
                  }`}
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
            </h2>
            <div
              className={`accordion-body py-4 px-5  ${
                activeIndex === index ? "block" : "hidden"
              }`}
            >
              <p className="text-justify">{item.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>  
  <div className="order-1 lg:order-2 w-full lg:w-1/3 md:w-1/2 px-0 md:px-4 mb-0">
    <div className="faqAssetsBox flex justify-center mt-0 md:mt-2">
      <img
        src={faqImage}
        width="400"
        height="470"
        alt="FAQ Assets"
        className="rounded-lg"
      />
    </div>
  </div>
  <div className="order-3 lg:order-3 w-full lg:w-1/3 md:w-1/2 px-0 md:px-4">
    <div className="rowtreebox">
      <div className="accordion">
        {faqData.slice(4, 8).map((item, index) => (
          <div key={index + 5} className="accordion-item border-t ">
            <h2 className="accordion-header mb-0">
              <button
                className="accordion-button flex items-center w-full py-4 px-5 text-base text-left text-white transition focus:outline-none"
                onClick={() => handleToggle(index + 4)}
              >
                {item.question}
                <svg
                  className={`ml-auto transform transition-transform ${
                    activeIndex === index + 4 ? "rotate-180" : ""
                  }`}
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
            </h2>
            <div
              className={`accordion-body py-4 px-5  ${
                activeIndex === index + 4 ? "block" : "hidden"
              }`}
            >
              <p className="text-justify">{item.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
        </div>

      </div>
    </section>
  );
};

const faqData = [
  {
    question: "What is Techmont?",
    answer:
      "Techmont is an innovative blockchain platform focused on NFT Staking, operating seamlessly on the Polygon Chain. As a technology company, we leverage the power of blockchain to develop advanced decentralized applications (dApps). Our primary mission is to create groundbreaking solutions applicable across various industries, enhancing the value and utility of digital assets for our users.",
  },
   

  {
    question: "Can I Register Without a Referral Link?",
    answer:
      "No, the registration process requires a referral code. You must enter a valid referral code before your account can be successfully created.",
  },
 
  {
    question: "What Swapping Pairs Are Available on Techmont?",
    answer: "Currently, Techmont supports the swapping pair of TMT to USDT. This pairing allows users to trade between TMT and USDT, providing a convenient way to manage assets within the platform.",
  },

  {
    question: "How Many Public NFTs Are Available for Users to Stake?",
    answer:
      "There are a total of 999 public NFTs available for users to stake on the platform. This wide variety of NFTs provides ample opportunities for users interested in participating in NFT staking.",
  },
  {
    question: "Which Wallet Should I Utilize?",
    answer: (
      <>
        Techmont seamlessly integrates with all cryptocurrency wallets. We recommend the following options:
        <br />
        <strong>For Mobile Devices (Smartphones, Tablets):</strong> The Metamask Wallet app is highly recommended.
        <br />
        iPhone users can download it{" "}
        <a
          href="https://apps.apple.com/us/app/metamask-blockchain-wallet/id1438144202"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 underline"
        >
          here
        </a>
        .
        <br />
        Android users can download it{" "}
        <a
          href="https://play.google.com/store/apps/details?id=io.metamask&hl=en&gl=US&pli=1"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 underline"
        >
          here
        </a>
        .
        <br />
        <strong>For Desktops/Laptops:</strong> Metamask can also be used on desktop browsers. You can access it{" "}
        <a
          href="https://metamask.io/download/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 underline"
        >
          here
        </a>
        .
      </>
    ),
  },
  {
    question: "What Are the Benefits of NFT Staking on Techmont?",
    answer:
      "NFT staking on Techmont offers users the opportunity to earn rewards while maintaining the security and decentralization of their assets. The platform is built on the Polygon Chain, ensuring fast and cost-efficient transactions. Users can also diversify their portfolios by earning rewards in the form of cryptocurrency or tokens.",
  },
  {
    question: "How Secure Is the Techmont Platform?",
    answer:
      "Techmont is built on the robust foundation of the Polygon Chain, ensuring a highly secure and decentralized environment for all staking activities. The platform utilizes smart contracts to facilitate fair and transparent rewards distribution, giving users full control over their assets.",
  },
  
  {
    question: "How Can I Learn More About Techmont’s Offerings?",
    answer: (
      <>
        You can explore more about Techmont's revolutionary offerings by visiting our official website at{" "}
        <a href="https://www.techmont.finance" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
          Techmont
        </a>. Here, you'll find detailed information about our NFT staking platform, token, swap feature, ITO, affiliate program, and more.
      </>
    ),
  }
  
  
];

export default Faqs;
