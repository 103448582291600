import React from "react";
import number1 from "../../Assets/images/number1.png";
import number2 from "../../Assets/images/number2.png";
import number3 from "../../Assets/images/number3.png";
import number4 from "../../Assets/images/4.png";
import number5 from "../../Assets/images/5.png";
import number6 from "../../Assets/images/6.png";
import number7 from "../../Assets/images/number3.png";
import number8 from "../../Assets/images/number3.png";

import "../Roadmap/Roadmap.css";

const Roadmap = () => {
  return (
    <section id="roadmapSrl" className="py-5 md:py-12 rm_section">
      <div className="container mx-auto px-2 sm:px-4">
        <div className="rm_main max-w-5xl mx-auto" data-aos="fade-up">


          <div className="h2div mx-auto w-full max-w-fit">
            <h3 className="text-center">Roadmap</h3>
          </div>

          <h2 className="adv-heading text-center text-white mt-3">
            Charting the Path to{" "}
            <span className="hero-communities">Digital </span> Empowerment{" "}
          </h2>
          
        </div>

        <div className="flex flex-wrap -mx-4 mt-8">
          <div className="w-full lg:w-1/3 md:w-1/2 px-4 mb-6 ">
            <div className="rm-item flex items-center relative" data-aos="fade-up">
              <div className="number-image flex-shrink-0 mr-4">
                <img src={number1} alt="Participate icon" className="block" />
              </div>
              <div className="participate-info">
                <h5 className="text-white text-xl font-semibold">
                Q3 & Q4 2024:
                </h5>
                <p className="description text-white mt-2">
                1.Market Research & Conceptualization
                </p>
                <p className="description text-white">2.Development Of Core Features</p>
              
              </div>

              <div className="section_rm-arrow-wrapper">
              <div className="section_rm-arrow w-embed">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.919 8.81575L9.06818 2.46382L10.8741 0.789396L20.8081 9.99992L10.8741 19.2104L9.06818 17.536L15.919 11.1841L0.373047 11.1841L0.373046 8.81575L15.919 8.81575Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
            </div>
           
          </div>
          <div className="w-full lg:w-1/3 md:w-1/2 px-4  mb-6">
            <div className="rm-item flex items-center relative" data-aos="fade-up">
              <div className="number-image flex-shrink-0 mr-4">
                <img src={number2} alt="Participate icon" className="block" />
              </div>
              <div className="participate-info">
                <h5 className="text-white text-xl font-semibold">Q1 2025:</h5>
                <p className="description text-white mt-2">
                1.NFT & ITO Launch & Platform Rollout
                </p>
               
              </div>

              <div className="section_rm-arrow-wrapper">
              <div className="section_rm-arrow w-embed">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.919 8.81575L9.06818 2.46382L10.8741 0.789396L20.8081 9.99992L10.8741 19.2104L9.06818 17.536L15.919 11.1841L0.373047 11.1841L0.373046 8.81575L15.919 8.81575Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
            </div>
         
          </div>
          <div className="w-full lg:w-1/3 md:w-1/2  px-4 mb-6">
            <div className="rm-item flex items-center relative" data-aos="fade-up">
              <div className="number-image flex-shrink-0 mr-4">
                <img src={number3} alt="Participate icon" className="block" />
              </div>
              <div className="participate-info">
                <h5 className="text-white text-xl font-semibold">Q2 2025:</h5>
                <p className="description text-white mt-2">
                1.Enhancing Platform Capabilities & User Experience
                </p>
              
              </div>

              <div className="section_rm-arrow-wrapper">
              <div className="section_rm-arrow w-embed">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.919 8.81575L9.06818 2.46382L10.8741 0.789396L20.8081 9.99992L10.8741 19.2104L9.06818 17.536L15.919 11.1841L0.373047 11.1841L0.373046 8.81575L15.919 8.81575Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
            </div>
           
          </div>
          <div className="w-full lg:w-1/3 md:w-1/2  px-4 mb-6">
            <div className="rm-item flex items-center relative" data-aos="fade-up">
              <div className="number-image flex-shrink-0 mr-4">
                <img
                  src={number4}
                  alt="Participate icon"
                  className="block opacity-[0.6]"
                />
              </div>

              <div className="participate-info">
                <h5 className="text-white text-xl font-semibold">Q3 2025:</h5>

                <p className="description text-white mt-2">
                1.Integration with Other Blockchains and Services
                </p>

            
              </div>

              <div className="section_rm-arrow-wrapper">
              <div className="section_rm-arrow w-embed">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.919 8.81575L9.06818 2.46382L10.8741 0.789396L20.8081 9.99992L10.8741 19.2104L9.06818 17.536L15.919 11.1841L0.373047 11.1841L0.373046 8.81575L15.919 8.81575Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
            </div>
          
          </div>
          <div className="w-full lg:w-1/3 md:w-1/2 px-4 mb-6 ">
            <div className="rm-item flex items-center relative" data-aos="fade-up">
              <div className="number-image flex-shrink-0 mr-4">
                <img
                  src={number5}
                  alt="Participate icon"
                  className="block opacity-[0.6]"
                />
              </div>
              <div className="participate-info">
                <h5 className="text-white text-xl font-semibold">Q4 2025:</h5>
                <p className="description text-white mt-2">
               1.Community-Driven Development and Governance
                </p>
               
              </div>

              <div className="section_rm-arrow-wrapper">
              <div className="section_rm-arrow w-embed">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.919 8.81575L9.06818 2.46382L10.8741 0.789396L20.8081 9.99992L10.8741 19.2104L9.06818 17.536L15.919 11.1841L0.373047 11.1841L0.373046 8.81575L15.919 8.81575Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
            </div>
           
          </div>
          <div className="w-full lg:w-1/3 md:w-1/2 px-4  mb-6">
            <div className="rm-item flex items-center relative" data-aos="fade-up">
              <div className="number-image flex-shrink-0 mr-4">
                <img
                  src={number6}
                  alt="Participate icon"
                  className="block opacity-[0.6]"
                />
              </div>
              <div className="participate-info">
                <h5 className="text-white text-xl font-semibold">Beyond 2026:</h5>

                <p className="description text-white mt-2">
                1.Coutinuous Innovation & Growth
                </p>

              
              </div>

              <div className="section_rm-arrow-wrapper">
              <div className="section_rm-arrow w-embed">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.919 8.81575L9.06818 2.46382L10.8741 0.789396L20.8081 9.99992L10.8741 19.2104L9.06818 17.536L15.919 11.1841L0.373047 11.1841L0.373046 8.81575L15.919 8.81575Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
            </div>
           
          </div>
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
