import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import logo from "../../Assets/images/1logo.png";

import "./header.css";

const Header = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const [showHamburger, setHamburger] = useState(false);
  const [DefiPrice, setDefiPrice] = useState();

  const Price = async () => {
    try {
      const response = await fetch("https://oc-price-api.onrender.com/oc-price");
      const json = await response.json();
      setDefiPrice((Math.floor(json.price * 100) / 100).toFixed(2));
    } catch (error) {
      console.log(error);
    }
  };

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
    setHamburger(!showHamburger);
  };

  useEffect(() => {
    Price();
    const interval = setInterval(() => {
      Price();
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setShowNavbar(false);
        setHamburger(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <header className={`${showNavbar ? "openmenu" : ""}`}>
        <div className="container mx-auto px-4">
          <div className=" items-center flex justify-between">
            <div className=" text-start">
              <div className="logo">
                <img src={logo} alt="logo" className="block w-18 h-auto" />
            
              </div>
            </div>
            <div className={` text-center hidden md:block position-relative`}>
              <ul className="menu list-unstyled m-0 p-0 flex space-x-4">
                <li>
                  <Link to="aboutusSrl" smooth={true} duration={0} className="cursor-pointer">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="advantagesSrl" smooth={true} duration={0} className="cursor-pointer">
                    Advantages
                  </Link>
                </li>
                <li>
                  <Link to="tokenomicsSrl" smooth={true} duration={0} className="cursor-pointer">
                    Tokenomics
                  </Link>
                </li>
                <li>
                  <Link to="roadmapSrl" smooth={true} duration={0} className="cursor-pointer">
                    Roadmap
                  </Link>
                </li>
                <li>
                  <Link to="faqsSrl" smooth={true} duration={0} className="cursor-pointer">
                    Faqs
                  </Link>
                </li>
              </ul>
            </div>
            <div className="text-end">
              <div className="rightboxmenu">
                {/* <ul className="balancebox list-unstyled bnrbtn flex space-x-2">
                  <li className="button-red-shadow w-button px-3 py-2">
                    Price<strong>$ --</strong>
                  </li>
                </ul> */}
                <div
                  className={`hamburger md:hidden ${showHamburger ? "is-active" : ""}`}
                  onClick={handleShowNavbar}
                >
                  <span className="line"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className={`menubar ${showNavbar ? "mobileMenu" : ""}`}>
        <ul className="menu list-unstyled m-0 p-0">
          <li>
            <Link to="aboutusSrl" smooth={true} duration={0} onClick={handleShowNavbar} className="cursor-pointer">
              About Us
            </Link>
          </li>
          <li>
            <Link to="advantagesSrl" smooth={true} duration={0} onClick={handleShowNavbar} className="cursor-pointer">
              Advantages
            </Link>
          </li>
          <li>
            <Link to="tokenomicsSrl" smooth={true} duration={0} onClick={handleShowNavbar} className="cursor-pointer">
              Tokenomics
            </Link>
          </li>
          <li>
            <Link to="roadmapSrl" smooth={true} duration={0} onClick={handleShowNavbar} className="cursor-pointer">
              Roadmap
            </Link>
          </li>
          <li>
            <Link to="faqsSrl" smooth={true} duration={0} onClick={handleShowNavbar} className="cursor-pointer">
              Faqs
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Header;
