import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Pages/Layout";

import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import loader from '../src/Assets/images/techmont.gif'

function App() {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
   
    setTimeout(() => {
      setLoading(false);
    }, 1000); 
  }, []);

  return (
    <BrowserRouter>
        {loading ? (
     
        <div className="loader-container">
          <span className="loader">
            <img src={loader} ></img>
          </span>
        </div>
      ) : (
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
        </Route>
      </Routes>
       )}
    </BrowserRouter>
  );
}

export default App;
